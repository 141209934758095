export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [];

export const dictionary = {
		"/": [9],
		"/Books": [~10,[2]],
		"/Books/MDA_Explained": [11,[2]],
		"/Books/MDA_Explained/Overview": [12,[2]],
		"/Books/Object_Constraint_Language": [13,[2]],
		"/Books/Overview": [14,[2]],
		"/Books/Praktisch_UML": [15,[2]],
		"/Freon": [~16,[3]],
		"/Freon/All": [17,[3]],
		"/Freon/Overview": [18,[3]],
		"/LionWeb": [~19,[4]],
		"/LionWeb/Overview": [20,[4]],
		"/Mdd": [~21,[5]],
		"/Mdd/Advantages": [22,[5]],
		"/Mdd/Dsl": [23,[5]],
		"/Mdd/ModelDriven": [24,[5]],
		"/Mdd/Overview": [25,[5]],
		"/News": [~26,[6]],
		"/News/About_the_Authors": [27,[6]],
		"/News/Freon_Release": [28,[6]],
		"/News/LionWeb-Release-2024": [29,[6]],
		"/News/Overview": [30,[6]],
		"/News/_DSL_Book": [31,[6]],
		"/Projects": [~32,[7]],
		"/Projects/Insurance_Product": [33,[7]],
		"/Projects/Medical_Trials_Dsl": [34,[7]],
		"/Projects/Mendix_Meta_Tooling": [35,[7]],
		"/Projects/Mobile_app_ide": [36,[7]],
		"/Projects/Overview": [37,[7]],
		"/Projects/Product_Modeling_Workbench": [38,[7]],
		"/Projects/Spray": [39,[7]],
		"/Projects/Tax_Office_ALEF": [40,[7]],
		"/Projects/Visual_Editor": [41,[7]],
		"/Services": [~42,[8]],
		"/Services/DSL_Development": [43,[8]],
		"/Services/Mentoring": [44,[8]],
		"/Services/Overview": [45,[8]],
		"/Services/Project_Execution": [46,[8]],
		"/Services/Research": [47,[8]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';